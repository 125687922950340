<script lang="ts">
	import { page } from '$app/stores'
	import LayoutCommon from '$lib/components/layout-common.svelte'
	import PageTransition from '$lib/components/page-transition.svelte'

	//^ init (life cycle and variables)

	//^ logic

	//^ computed
</script>

<LayoutCommon>
	<main class="">
		<PageTransition refresh={$page.path}>
			<slot />
		</PageTransition>
	</main>
</LayoutCommon>
